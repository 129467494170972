import React from "react"
import PropTypes from "prop-types"

const Layout = ({ children, twoColumn = false, smGap = false, mdGap = false, lgGap = false, }) => {
  const defaultStyles = "grid grid-cols-1"
  const defaultGapStyles = "gap-4 md:gap-6"
  const smGapStyles = "gap-6"
  const mdGapStyles = "gap-16"
  const lgGapStyles = "gap-20"
  const defaultColumns = "md:grid-cols-2 lg:grid-cols-3"
  const twoColumnStyles = "md:grid-cols-2 lg:grid-cols-2"

  return (
    <div
      className={
        defaultStyles +
        " " +
        (twoColumn ? twoColumnStyles : defaultColumns) +
        " " +
        (!mdGap && !lgGap && !smGap ? defaultGapStyles : "") +
        " " +
        (mdGap && !lgGap && !smGap ? mdGapStyles : "") +
        " " +
        (lgGap && !mdGap && !smGap ? lgGapStyles : "") +
        " " +
        (smGap && !mdGap && !lgGap ? smGapStyles : "")
      }
    >
      {children}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
