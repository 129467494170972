import React from "react"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { convertRaw, renderRawText } from "../../helpers/rich-text"

const Poem = ({ poemPart1, poemPart2 }) => {
  return (
    <div className="flex flex-col py-6 md:py-20">
      <div className="text-sm tracking-widest text-gold mb-6">
        {documentToReactComponents(convertRaw(poemPart1), renderRawText)}
      </div>
      <div className="text-sm tracking-widest text-gold text-center">
        {documentToReactComponents(convertRaw(poemPart2), renderRawText)}
      </div>
    </div>
  )
}

Poem.propTypes = {
  poemPart1: PropTypes.object,
  poemPart2: PropTypes.object,
}

export default Poem
