import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { convertRaw, renderRawText } from "../../helpers/rich-text"

const QualityCategory = ({ title, subtitle, text, image }) => {
  return (
    <div className="flex flex-col mb-8 md:mb-20">
      <h3 className="text-gray-600 text-lg tracking-widest mb-3">
        {title ? title : "\u00A0"}
      </h3>
      <h4 className="text-gray-600 text-base tracking-widest mb-6">
        {subtitle ? subtitle : "\u00A0"}
      </h4>
      {text ? (
        <div className="text-xs tracking-widest text-gray-500 mb-4">
          {documentToReactComponents(convertRaw(text), renderRawText)}
        </div>
      ) : null }

      {image && (
        <div className="mt-auto">
          <GatsbyImage
            className="aspect-w-1 aspect-h-1 w-full h-full object-cover"
            image={image?.gatsbyImageData}
            alt={image?.title ? image?.title : ""}
          />
        </div>
      )}
    </div>
  )
}

QualityCategory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.object,
}

export default QualityCategory
