// Gatsby supports TypeScript natively!
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"
import Grid from "../components/grid"
import QualityCategory from "../components/buero/quality-category"
import Poem from "../components/buero/poem"

const Arbeitsweise = ({
  path,
  data: {
    page: { keyvisual, poemPart1, poemPart2, qualityTitle, qualityList },
  },
}) => {

  return (
    <Layout path={path}>
      <Seo title="Büro - Arbeitsweise" />
      <Container fullWidth maxWidth5xl>
        <div className="relative">
          {keyvisual && (
            <GatsbyImage
              className="aspect-w-16 aspect-h-7 w-full h-full object-cover"
              image={keyvisual?.gatsbyImageData}
              alt={keyvisual?.title ? keyvisual?.title : ""}
            />
          )}
          <div className="hidden lg:block absolute top-0 left-0 z-20 w-full h-full bg-white bg-opacity-30">
            <Container>
                <Poem poemPart1={poemPart1} poemPart2={poemPart2} />
            </Container>
          </div>
        </div>
      </Container>
      <Container verticalSpaceSm>
        <div className="text-center mb-8 md:mb-16 text-xl tracking-widest text-gray-600">
          {qualityTitle}
        </div>
        <Grid>
          {qualityList.map((qualityCategory, index) => {
            return (
              <QualityCategory
                key={`quality-${index}`}
                title={qualityCategory.title}
                subtitle={qualityCategory.subtitle}
                text={qualityCategory.text ? qualityCategory.text : ''}
                image={qualityCategory.image}
              />
            )
          })}
        </Grid>
      </Container>
    </Layout>
  )
}

export default Arbeitsweise

export const pageQuery = graphql`
  query ArbeitsweiseQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    page: contentfulArbeitsweise(id: { eq: "5261b734-f3b9-5f0f-a351-b6696f2535a6" }) {
      keyvisual {
        gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 40)
      }
      poemPart1 {
        raw
      }
      poemPart2 {
        raw
      }
      qualityTitle
      qualityList {
        title
        subtitle
        text {
          raw
        }
        image {
          gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 40)
        }
      }
    }
  }
`
